<template>
	<span>
		<span @click="toggle">
			<slot name="reference" />
		</span>

		<a-modal v-if="isShow" :visible="true" :title="itemData ? $t('action.edit') : $t('action.add')" width="800px" @cancel="toggle">
			<a-form-model ref="formRef" :model="formData" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
				<a-form-model-item prop="payeeBankCode" :label="$t('table.Payee-Bank-Institution')">
					<a-select v-model="formData.payeeBankCode">
						<a-select-option v-for="item in enumerate.bankList" :key="item.code" :value="item.code">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>

				<a-form-model-item prop="disburseName" :label="$t('table.Payee-Name')">
					<a-input v-model="formData.disburseName" :max-length="50" />
				</a-form-model-item>

				<a-form-model-item
					prop="disburseAccount"
					:label="formData.payeeBankCode === 'M-PESA' ? $t('table.Payee-Mobile-Number') : $t('table.Payee-Account-Number')"
				>
					<span class="row-start-center">
						<span v-if="formData.payeeBankCode === 'M-PESA'" style="margin-right: 10px">+254</span>
						<a-input v-model="formData.disburseAccount" :max-length="50" />
					</span>
				</a-form-model-item>
				<!-- <a-form-model-item prop="payeeType" :label="$t('table.Payee-Type')">
					<a-select v-model="formData.payeeType" :disabled="formData.payeeBankCode !== '46'">
						<a-select-option v-for="item in enumerate.payeeType" :key="item.value" :value="item.value">
							{{ item.label }}
						</a-select-option>
					</a-select>
				</a-form-model-item> -->

				<a-form-model-item prop="disburseAmount" :label="$t('table.Disbursement-Amount')">
					<a-input v-model="formData.disburseAmount" :max-length="50" />
				</a-form-model-item>

				<a-form-model-item prop="messageToBeneficiary" :label="$t('table.Message-to-Beneficiary')">
					<a-textarea v-model="formData.messageToBeneficiary" :max-length="100" />
				</a-form-model-item>

				<a-form-model-item prop="remark" :label="$t('table.remark')">
					<a-textarea v-model="formData.remark" :max-length="255" :auto-size="{ minRows: 3, maxRows: 5 }" />
				</a-form-model-item>
			</a-form-model>

			<template slot="footer">
				<a-button :loading="isLoadingSave" :disabled="!!!itemData && tableData?.length >= 10" @click="save()"> Save </a-button>
				<a-button v-if="!itemData" @click="save(true)" :disabled="tableData?.length >= 10"> Save & Create New </a-button>
				<a-button type="primary" @click="submit"> Submit </a-button>
			</template>
		</a-modal>
	</span>
</template>

<script>
import { mapGetters } from 'vuex'
import { addOrUpdateDisburseDraft, submitDisburseDraft } from '@/api/loan'
import { Rule, resetFormData, cloneTargetValue } from '@/utils'

export default {
	props: {
		tableData: {
			type: Array
		},
		itemData: {
			type: Object
		},
		onSaveSuccess: {
			type: Function
		}
	},
	data() {
		return {
			isShow: false,
			formData: {
				payeeBankCode: 'M-PESA',
				disburseName: '',
				disburseAccount: '',
				// payeeType: '',
				disburseAmount: '',
				messageToBeneficiary: '',
				remark: ''
			},
			isLoadingSave: false
		}
	},
	computed: {
		...mapGetters(['enumerate']),
		rules() {
			return {
				disburseName: [Rule.requiredInput],
				disburseAccount: [Rule.requiredInput],
				// payeeType: [Rule.requiredSelect],
				disburseAmount: [
					Rule.requiredInput,
					this.formData.payeeBankCode === 'M-PESA' ? Rule.inputInteger() : Rule.inputFloat(),
					this.formData.payeeBankCode === 'M-PESA' ? Rule.inputNumberRange(10, 250000) : Rule.inputNumberRange(0, 999999.99)
				],
				messageToBeneficiary: [Rule.inputLetterNumber()]
			}
		}
	},
	watch: {
		'formData.payeeBankCode': {
			handler: function (newValue) {
				// if (newValue && newValue === '46') {
				// 	this.formData.payeeType = ''
				// } else {
				// 	this.formData.payeeType = 0
				// }
				this.$refs['formRef']?.clearValidate()
			}
		}
	},
	methods: {
		toggle() {
			this.isShow = !this.isShow
			if (this.isShow) {
				this.resetForm()
				// 编辑
				if (this.itemData) {
					cloneTargetValue(this.formData, this.itemData)
				}
			}
		},
		resetForm() {
			resetFormData(this.formData)
			this.formData.payeeBankCode = 'M-PESA'
			// this.formData.payeeType = 0
		},
		async save(isCreateNew = false) {
			try {
				await this.$refs['formRef'].validate()
				this.isLoadingSave = true
				const params = {
					...this.formData,
					id: this.itemData?.id,
					loanId: this.$route.query.loanId,
					loanType: this.$route.query.loanType,
					disburseLoanType: Number(this.$route.query.disburseLoanType),
					disburseType: 'CHOICE'
				}
				const res = await addOrUpdateDisburseDraft(params)
				this.$message.success('Success')
				this.$emit('onSaveSuccess', res)
				if (isCreateNew) {
					this.resetForm()
				} else {
					this.toggle()
				}
			} finally {
				this.isLoadingSave = false
			}
		},
		async submit() {
			try {
				await this.$refs['formRef'].validate()
				this.isLoadingSave = true
				const params = {
					...this.formData,
					id: this.itemData?.id,
					loanId: this.$route.query.loanId,
					loanType: this.$route.query.loanType,
					disburseLoanType: Number(this.$route.query.disburseLoanType),
					disburseType: 'CHOICE'
				}
				const res = await submitDisburseDraft(params)
				this.$message.success('Success')
				this.$emit('onSaveSuccess', res)
				this.toggle()
			} finally {
				this.isLoadingSave = false
			}
		}
	}
}
</script>
<style lang="less" scope></style>
