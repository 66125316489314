<template>
	<div>
		<span class="row-start-center">
			<span style="font-size: 18px; margin-right: 10px">Choice Bank</span>
			<img class="img-bank" src="@/assets/choicebank.png" />
		</span>
		<div style="margin-top: 40px">
			<span style="font-size: 18px">Draft</span>
			<edit-disburse-draft-modal :tableData="tableData" @onSaveSuccess="(newValue) => (tableData = newValue)">
				<a-button slot="reference" type="primary" ghost style="margin-left: 10px" :disabled="tableData?.length >= 10">
					{{ tableData?.length > 0 ? 'Add' : 'Create Now' }}
				</a-button>
			</edit-disburse-draft-modal>
			<a-popconfirm
				v-if="tableData?.length > 0"
				:ok-text="$t('enumerate.yes-no.1')"
				:cancel-text="$t('enumerate.yes-no.0')"
				:title="$t('page.confirm')"
				@confirm="submitAll"
			>
				<a-button type="primary" style="margin-left: 10px"> Submit Disbursement(s) </a-button>
			</a-popconfirm>
		</div>
		<a-table
			:loading="isLoadingTable"
			:columns="draftColumns"
			:data-source="tableData"
			:row-key="(row, index) => index"
			bordered
			style="margin-top: 10px"
		>
			<span slot="disburseAmount" slot-scope="data">
				<a-statistic :precision="2" :value="data.disburseAmount" />
			</span>
			<span slot="action" slot-scope="data">
				<edit-disburse-draft-modal :tableData="tableData" :itemData="data" @onSaveSuccess="(newValue) => (tableData = newValue)">
					<a-button slot="reference" type="link"> Edit </a-button>
				</edit-disburse-draft-modal>
				<a-popconfirm
					:ok-text="$t('enumerate.yes-no.1')"
					:cancel-text="$t('enumerate.yes-no.0')"
					:title="$t('page.confirm')"
					@confirm="deleteDraft(data)"
				>
					<a-button type="link" style="color: #f5222d"> Delete </a-button>
				</a-popconfirm>
			</span>
		</a-table>
	</div>
</template>

<script>
import { getDisburseInfo, deleteDisburseDraft, submitAllDisburseDrafts } from '@/api/loan'
import EditDisburseDraftModal from './components/edit-disburse-draft-modal.vue'
import { getBankInfo } from '@/utils'

export default {
	components: {
		EditDisburseDraftModal
	},
	data() {
		return {
			tableData: [],
			isLoadingTable: false
		}
	},
	computed: {
		draftColumns() {
			return [
				{
					title: 'No.',
					align: 'center',
					customRender: (row, row2, index) => index + 1
				},
				{
					title: this.$t('table.Payee-Bank-Institution'),
					align: 'center',
					customRender: (row) => getBankInfo(row.payeeBankCode).name
				},
				{
					title: this.$t('table.Payee-Name'),
					dataIndex: 'disburseName',
					align: 'center'
				},
				{
					title: this.$t('table.Payee-Account-Number'),
					dataIndex: 'disburseAccount',
					align: 'center'
				},
				// {
				// 	title: this.$t('table.Payee-Type'),
				// 	align: 'center',
				// 	customRender: (row) => row.payeeType !== null && this.$t('enumerate.payee-type' + `.${row.payeeType}`)
				// },
				{
					title: this.$t('table.Disbursement-Amount'),
					scopedSlots: { customRender: 'disburseAmount' },
					align: 'center'
				},
				{
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center'
				}
			]
		}
	},
	mounted() {
		this.fetchDisburseInfoData()
	},
	methods: {
		async fetchDisburseInfoData() {
			try {
				this.isLoadingTable = true
				const params = {
					loanId: this.$route.query.loanId,
					loanType: this.$route.query.loanType,
					disburseLoanType: Number(this.$route.query.disburseLoanType)
				}
				this.tableData = await getDisburseInfo(params)
			} finally {
				this.isLoadingTable = false
			}
		},
		async submitAll() {
			const params = {
				ids: this.tableData.filter((i) => i.status === 0).map((j) => j.id),
				loanId: this.$route.query.loanId,
				loanType: this.$route.query.loanType,
				disburseLoanType: Number(this.$route.query.disburseLoanType)
			}
			this.tableData = await submitAllDisburseDrafts(params)
			this.$message.success('Success')
		},
		async deleteDraft(itemData) {
			const params = {
				id: itemData.id,
				loanId: this.$route.query.loanId,
				loanType: this.$route.query.loanType,
				disburseLoanType: Number(this.$route.query.disburseLoanType)
			}
			this.tableData = await deleteDisburseDraft(params)
			this.$message.success('Success')
		}
	}
}
</script>

<style lang="less" scoped>
.img-bank {
	height: 48px;
	border: 1px solid #e8e8e8;
	border-radius: 24px;
	padding: 0px 24px;
}

/deep/ .ant-statistic-content-value-int,
/deep/ .ant-statistic-content-value-decimal {
	font-size: 14px;
	color: #000000a6;
}
</style>
